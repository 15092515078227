'use strict';

angular.module('app')
    .controller('DisclosureDeclarationController', ['ConferencesService', 'FacultyService', 'UtilsService', 'CategoriesService', '$http', '$state', '$rootScope', '$scope', '$stateParams', '$filter', '$log', '$window', 'toaster', '$localStorage',
            function (Conferences, Faculty, Utils, Categories, $http, $state, $rootScope, $scope, $stateParams, $filter, $log, $window, toaster, $localStorage) {

                if ($scope.disclosure_section == 'global') {
                    $scope.year = new Date().getFullYear();
                }

                var conference_id = $scope.conference_id ? $scope.conference_id : null;

                $scope.conflicts = [];
                $scope.conflicts_model = {};
                $scope.diclosure_model = {};
                $scope.disclosure = {};

                // get full faculty profile
                Faculty.getFaculty().then(function (data) {

                    if (data && data.success) {
                        $scope.faculty = data.faculty;
                    }
                })

                // Get companies for conflict form
                Categories.getCategory('companies').then(function (data) {

                    if (data && data.success) {
                        $scope.companies = data.data;
                    }
                });

                // Get conflict nature of relationship types for conflict form
                Categories.getCategory('conflict-relationship-type').then(function (data) {

                    if (data && data.success) {

                        $scope.relationships = [];

                        angular.forEach(data.data, function (value) {
                            $scope.relationships.push({'relationship_title': value.name})
                        })
                    }
                });

                // Get conflict relationship types for conflict form
                $scope.relationship_types = [
                    {'relationship_type_title': 'Personal'},
                    {'relationship_type_title': 'Institutional'},
                    {'relationship_type_title': 'Both'}
                ]

                // Get disclosure content including conflicts
                Faculty.getDisclosure(conference_id).then(function (data) {

                    var default_conflicts = [
                        {id: 1, relationship_title: '', company_name: '', relationship_type_title: ''},
                        {id: 2, relationship_title: '', company_name: '', relationship_type_title: ''},
                        {id: 3, relationship_title: '', company_name: '', relationship_type_title: ''},
                        {id: 4, relationship_title: '', company_name: '', relationship_type_title: ''},
                        {id: 5, relationship_title: '', company_name: '', relationship_type_title: ''},
                    ];

                    if (data && data.success) {

                        $scope.disclosure = data.disclosure;

                        $scope.disclosure_model = {
                            conflict_none: data.disclosure.conflict_none == 'yes' ? true : false,
                            conflict_exists_1: data.disclosure.conflict_exists_1 == 'yes' ? true : false,
                            off_label: data.disclosure.off_label,
                            off_devices: data.disclosure.off_devices,
                            confirm_1: $scope.show_confirm ? data.disclosure.confirm_1 : null,
                            confirm_2: $scope.show_confirm ? data.disclosure.confirm_2 : null,
                        }

                        $scope.policy_read = $scope.show_confirm ? true : false;

                        if (data.disclosure.conflicts && data.disclosure.conflicts.length) {
                            $scope.conflicts = data.disclosure.conflicts;
                        } else {
                            $scope.conflicts = default_conflicts;
                        }

                    } else if (data.status == 'error') {
                        $scope.conflicts = default_conflicts;
                        alert(data.error);
                    } else {
                        $scope.conflicts = default_conflicts;
                    }
                });

                $scope.$watch('disclosure_model.conflict_none', function (new_val, old_val) {
                    if (new_val){
                        $scope.disclosure_model.conflict_exists_1 = false;
                    }
                })

                $scope.$watch('disclosure_model.conflict_exists_1', function (new_val, old_val) {
                    if (new_val){
                        $scope.disclosure_model.conflict_none = false;
                    }
                })

                // Delete conflict
                $scope.removeConflict = function (conflict) {

                    angular.forEach($scope.conflicts, function (val, key) {
                        if (val.id == conflict.id) {
                            var index = $scope.conflicts.indexOf(conflict);

                            if (index !== -1) {
                                $scope.conflicts.splice(index, 1);
                                $scope.conflicts_model[conflict.id] = null;
                            }
                        }
                    });
                }

                // Add more conflict items
                $scope.addConflict = function () {

                    var next_id = 0;

                    angular.forEach($scope.conflicts, function (val, key) {
                        if (val.id > next_id) {
                            next_id = val.id;
                        }
                    });

                    next_id++;

                    $scope.conflicts.push({
                        id: next_id,
                        relationship_title: '',
                        company_name: '',
                        relationship_type_title: ''
                    });
                }

                // Check for any selected checkboxes
                $scope.someSelected = function (object, required_flag) {

                    if (required_flag) {
                        if (!angular.isUndefined(object)) {
                            return Object.keys(object).some(function (key) {
                                return object[key];
                            });
                        }
                    } else {
                        return true;
                    }
                }

                $scope.saveTask = function () {

                    $scope.error_msg = '';
                    var error_msg = '';
                    var error_text = '';

                    var form_vars = {
                        disclosure: {
                            conference_faculty_id: $localStorage.faculty_id,
                            conflict_none: $scope.disclosure_model.conflict_none ? 'yes' : '',
                            conflict_exists_1: $scope.disclosure_model.conflict_exists_1 ? 'yes' : '',
                            off_label: $scope.disclosure_model.off_label,
                            off_devices: $scope.disclosure_model.off_devices,
                            confirm_1: $scope.disclosure_model.confirm_1,
                            confirm_2: $scope.disclosure_model.confirm_2,
                        },
                        conflicts: []
                    }

                    var incomplete_conflicts = [];

                    if (!$scope.policy_read) {
                        error_text = 'Please read the full Disclosure Declaration policy (Scroll down and read to accept policy)';
                        $scope.error_msg += error_text + '<br>';
                        error_msg += error_text + '|';
                    }

                    if (!$scope.disclosure_model.conflict_none && !$scope.disclosure_model.conflict_exists_1) {
                        error_text = 'Please indicate if you have any conflicts of interest.';
                        $scope.error_msg += error_text + '<br>';
                        error_msg += error_text + '|';
                    }

                    if ($scope.disclosure_model.conflict_exists_1) {

                        var conflicts_model_keys = Object.keys($scope.conflicts_model);
                        var conflicts_len = conflicts_model_keys.length;

                        if (conflicts_len) {
                            angular.forEach($scope.conflicts_model, function (value, key) {
                                if (value) {
                                    var conflict = {
                                        relationship_title: null,
                                        relationship_title_other: null,
                                        company_name: null,
                                        company_name_other: null,
                                        position_title: null,
                                        relationship_type_title: null
                                    }

                                    if (value.company) {
                                        conflict.company_name = value.company.name;
                                    }

                                    if (value.company_other && value.company.name == 'Other') {
                                        conflict.company_name_other = value.company_other;
                                    }

                                    if (value.relationship) {
                                        conflict.relationship_title = value.relationship.relationship_title;
                                    }

                                    if (value.relationship_other && value.relationship.relationship_title == 'Other') {
                                        conflict.relationship_title_other = value.relationship_other;
                                    }

                                    if (value.position_title && value.relationship.relationship_title == 'Company Position Title (i.e. Chief Medical Officer, Director, etc)') {
                                        conflict.position_title = value.position_title;
                                    }

                                    if (value.relationship_type) {
                                        conflict.relationship_type_title = value.relationship_type.relationship_type_title;
                                    }

                                    var company_name_ok = false;
                                    var relationship_ok = false;
                                    var position_title_ok = false;
                                    var relationship_type_ok = false;

                                    if (conflict.company_name) {
                                        if (conflict.company_name == 'Other') {
                                            if (conflict.company_name_other) {
                                                company_name_ok = true;
                                            }
                                        } else {
                                            company_name_ok = true;
                                        }
                                    }

                                    if (conflict.relationship_title) {
                                        if (conflict.relationship_title == 'Other') {
                                            if (conflict.relationship_title_other) {
                                                relationship_ok = true;
                                            }
                                        } else {
                                            relationship_ok = true;
                                        }

                                        if (conflict.relationship_title == 'Company Position Title (i.e. Chief Medical Officer, Director, etc)') {
                                            if (conflict.position_title) {
                                                position_title_ok = true;
                                            }
                                        } else {
                                            position_title_ok = true;
                                        }
                                    }

                                    if (conflict.relationship_type_title) {
                                        relationship_type_ok = true;
                                    }

                                    if (relationship_ok && company_name_ok && position_title_ok && relationship_type_ok) {
                                        form_vars.conflicts.push(conflict);
                                    } else if (relationship_ok || company_name_ok || position_title_ok || relationship_type_ok) {
                                        incomplete_conflicts.push(conflict);
                                    }
                                }
                            })
                        }

                        if (form_vars.conflicts.length == 0) {
                            error_text = 'Please fill in all details for at least one financial affiliation';
                            $scope.error_msg += error_text + '<br>';
                            error_msg += error_text + '|';
                        }

                        if (incomplete_conflicts.length) {
                            error_text = 'Please fill out all fields for the following conflicts:';
                            angular.forEach(incomplete_conflicts, function (value, key) {
                                error_text += '<br> - <strong>Relationship:</strong> '
                                    + value.relationship_title + ' ' + (value.relationship_title_other ? value.relationship_title_other : '')
                                    + ' <strong>Company:</strong> '
                                    + value.company_name + ' ' + (value.company_name_other ? value.company_name_other : '')
                                    + ' <strong>Type of Relationship</strong> '
                                    + value.relationship_type_title;
                            })
                            $scope.error_msg += error_text + '<br>';
                            error_msg += error_text + '|';
                        }

                    } else {
                        form_vars.conflicts = [];
                    }

                    if ($scope.disclosure_section == 'faculty') {
                        if (!$scope.disclosure_model.off_label) {
                            error_text = 'Please answer \'Off-label/unapproved uses are included in my presentation(s) / material(s).\'';
                            $scope.error_msg += error_text + '<br>';
                            error_msg += error_text + '|';
                        } else if ($scope.disclosure_model.off_label == 'yes') {
                            if (!$scope.disclosure_model.off_devices) {
                                error_text = 'Please answer \'List the drug/device (no trade names) and to what purpose they will be presented:\'';
                                $scope.error_msg += error_text + '<br>';
                                error_msg += error_text + '|';
                            }
                        }
                    }

                    if (!$scope.disclosure_model.confirm_1 || !$scope.disclosure_model.confirm_2) {
                        error_text = 'To continue you must check the 2 boxes certifying you have disclosed all conflicts';
                        $scope.error_msg += error_text + '<br>';
                        error_msg += error_text + '|';
                    }

                    if ($scope.error_msg == '') {

                        switch ($scope.disclosure_section) {
                            case 'global':

                                Faculty.updateGlobalDisclosure(form_vars).then(function (data) {
                                    if (data && data.success) {

                                        toaster.pop('success', 'Annual Disclosure', 'Saved Successfully!', {'time-out' : 350});
                                        $state.go('app.home');

                                    } else if (data && !data.success) {
                                        toaster.pop('error', 'Error', Utils.formatErrorMsg(data.message));
                                    } else {
                                        toaster.pop('error', 'Error', 'Disclosure update failed');
                                    }
                                });

                                break;
                            case 'faculty':

                                Faculty.updateDisclosure($scope.conference_id, form_vars).then(function (data) {
                                    if (data && data.success) {

                                        // broadcast
                                        $rootScope.$broadcast('disclosureUpdated', {section: $scope.disclosure_section});

                                    } else if (data && !data.success) {
                                        toaster.pop('error', 'Error', Utils.formatErrorMsg(data.message));
                                    } else {
                                        toaster.pop('error', 'Error', 'Disclosure update failed');
                                    }
                                });

                                break;
                            case 'planner_faculty':

                                Faculty.updateDisclosure($scope.conference_id, form_vars).then(function (data) {
                                    if (data && data.success) {

                                        // broadcast
                                        $rootScope.$broadcast('disclosureUpdated', {section: $scope.disclosure_section});

                                    } else if (data && !data.success) {
                                        toaster.pop('error', 'Error', Utils.formatErrorMsg(data.message));
                                    } else {
                                        toaster.pop('error', 'Error', 'Disclosure update failed');
                                    }
                                });

                                break;
                            default:
                                break;
                        }

                    } else {
                        toaster.pop({
                            'type': 'custominfo',
                            'title': 'Please complete the following:',
                            'body': Utils.formatErrorMsg(error_msg),
                            'showCloseButton': true,
                            'closeHtml': '<button class="pull-right">X</button>',
                            'position-class': 'toast-top-full-width',
                            'time-out': '350'
                        });
                    }
                }

                $scope.setPolicyRead = function () {
                    $scope.policy_read = true;
                }

                $scope.cancelTask = function () {
                    $state.go('app.conference_tasks', {
                        'conference_id': $scope.conference_id
                    });
                }
            }
        ]
    );