'use strict';

angular.module('app')
    .directive('checkUser', ['$rootScope', '$location', 'AuthService',
        function ($r, $location, Auth) {
            return {
                link: function (scope, elem, attrs, ctrl) {
                    $r.$on('$routeChangeStart', function (e, curr, prev) {
                        if (!prev.authenticate && !Auth.Authenticated) {
                            // reload the login route
                            alert('reload LOGIN');
                        }
                        /*
                         * IMPORTANT:
                         * It's not difficult to fool the previous control,
                         * so it's really IMPORTANT to repeat server side
                         * the same control before sending back reserved data.
                         */
                    });
                }
            }
        }
    ]
);

