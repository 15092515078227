'use strict'

angular.module('app').filter('dateMysql', ['$filter',
    function(filter){
        return function(date, format) {
            if(typeof date != 'undefined'){
                var dateFilter = filter('date');
                var newFormat;

                if(date == '0000-00-00 00:00:00 UTC'){
                    return;
                }

                switch(format){
                    case 'short':
                        newFormat = "EEEE, MMMM d";
                        break;
                    case 'dateTime':
                        newFormat = "EEEE, MMMM dd, yyyy - h:mm a";
                        break;
                    case 'dateTime_short':
                        newFormat = "EEEE, MMMM dd, yyyy - h:mm a";
                        break;
                    default:
                        newFormat = format;
                        break;
                }

                var t = date.split(/[- :]/);

                var newDate = t[0] + '-' + t[1] + '-' + t[2] + 'T' + t[3] + ':' + t[4] + ':' + t[5] + '.000000Z';
                // Apply each element to the Date function
                var d = new Date(newDate);
                var newDate = d.toISOString();
                return dateFilter(newDate, newFormat);
            }
        };
    }
]);