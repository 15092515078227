'use strict';

angular.module('app')
    .directive('footableSaveStateFilter', [
            function () {
                return function(scope, element, attrs) {
                    element.bind('change', function() {


                        $('#myDataTable').bind({
                            'footable_initialized': function (e) {

                                var $table = $('#myDataTable');

                                var sortdata = JSON.parse(sessionStorage.getItem('Footable_sort_' + window.location.pathname));
                                if (sortdata) {
                                    $table.data('footable-sort').doSort(sortdata.index, sortdata.ascending);
                                }

                                var filterdata = JSON.parse(sessionStorage.getItem('Footable_filter_' + window.location.pathname));
                                if (filterdata) {
                                    $table.data('footable-filter').filter(filterdata.filter);
                                    $($table.data('filter')).val(filterdata.filter);
                                }

                            },
                            'footable_sorted': function (e) {
                                var sortdata = { index: e.column.index, ascending: (e.direction == "ASC") };
                                sessionStorage.setItem('Footable_sort_' + window.location.pathname, JSON.stringify(sortdata));
                            },
                            'footable_filtered': function (e) {
                                var filterdata = { filter: e.filter };
                                sessionStorage.setItem('Footable_filter_' + window.location.pathname, JSON.stringify(filterdata));
                            }
                        }).footable();



                        var filtering = FooTable.get('#submissions-grade').use(FooTable.Filtering), // get the filtering component for the table
                            filter = '"'+$(this).val()+'"'; // get the value to filter by
                        console.log(filter)
                        if (filter === 'all'){ // if the value is "all" remove the filter
                            filtering.removeFilter('status');
                            filtering.clear();
                        } else { // otherwise add/update the filter.
                            filtering.addFilter('status', filter, ['status']);
                            filtering.filter();
                        }

                    })
                }
            }
        ]
    );

