'use strict';

angular.module('app')
    .directive('footableReviewStatusFilter', [
            function () {
                return function(scope, element, attrs) {
                    element.bind('change', function() {

                        var filtering = FooTable.get('#submissions-grade').use(FooTable.Filtering), // get the filtering component for the table
                            filter = $(this).val(); // get the value to filter by
                        
                        if (filter === 'all'){ // if the value is "all" remove the filter
                            filtering.removeFilter('status');
                            filtering.clear();
                        } else { // otherwise add/update the filter.
                            filtering.addFilter('status', filter, ['status']);
                            filtering.filter();
                        }

                    })
                }
            }
        ]
    );

