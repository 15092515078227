'use strict'

angular.module('app').factory('FacultyService', ['$rootScope', '$http', '$log', '$localStorage',
    function ($rootScope, $http, $log, $localStorage) {
        return {
            getFaculty: function () {

                var promise = $http.get($rootScope.global_app.uri + '/faculty/' + $localStorage.faculty_id).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            getConferenceFaculty: function (conference_id) {

                var promise = $http.get($rootScope.global_app.uri + '/conferences/' + conference_id + '/faculty/' + $localStorage.faculty_id).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            getFacultyConference: function (conference_id) {

                var promise = $http.get($rootScope.global_app.uri + '/faculty/' + $localStorage.faculty_id + '/conferences/' + conference_id).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            getConference: function (conference_id) {

                var promise = $http.get($rootScope.global_app.uri + '/faculty/' + $localStorage.faculty_id + '/conferences/' + conference_id).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            getConferences: function (date) {

                var query = '';

                if (date) {
                    query = '?search_datetime_start=gt ' + date;
                }

                var promise = $http.get($rootScope.global_app.uri + '/faculty/' + $localStorage.faculty_id + '/conferences' + query).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            getCompanies: function () {

                var promise = $http.get($rootScope.global_app.uri + '/categories/companies', {
                    ignoreLoadingBar: true
                }).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;

            },
            getDisclosure: function (conference_id) {
                if (conference_id){
                    var url = $rootScope.global_app.uri + '/faculty/' + $localStorage.faculty_id + '/conferences/' + conference_id + '/disclosures';
                } else {
                    var url = $rootScope.global_app.uri + '/faculty/' + $localStorage.faculty_id + '/disclosures';
                }

                var promise = $http.get(url).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            getFacultyConferenceDisclosure: function (faculty_id, conference_id) {
                var promise = $http.get($rootScope.global_app.uri + '/faculty/' + faculty_id + '/conferences/' + conference_id + '/disclosures').then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            getAvailability: function (faculty_id, conference_id) {
                var promise = $http.get($rootScope.global_app.uri + '/conferences/' + conference_id + '/faculty/' + faculty_id + '/availability').then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            updateFaculty: function (form_vars) {

                var promise = $http.put($rootScope.global_app.uri + '/faculty/' + $localStorage.faculty_id, form_vars).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            updateConferenceFaculty: function (conference_id, form_vars) {

                var promise = $http.put($rootScope.global_app.uri + '/faculty/' + $localStorage.faculty_id + '/conferences/' + conference_id, form_vars).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            updateAssignments: function (conference_id, form_vars) {

                var promise = $http.put($rootScope.global_app.uri + '/conferences/' + conference_id + '/faculty/' + $localStorage.faculty_id + '/assignments', form_vars).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            updateDisclosure: function (conference_id, form_vars) {

                var promise = $http.post($rootScope.global_app.uri + '/faculty/' + $localStorage.faculty_id + '/conferences/' + conference_id + '/disclosures', form_vars).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            updateGlobalDisclosure: function (form_vars) {

                var promise = $http.post($rootScope.global_app.uri + '/faculty/' + $localStorage.faculty_id + '/disclosures', form_vars).then(function (response) {
                    return response.data;
                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            },
            downloadCalendarAssignments: function (conference_id) {

                var download_link = '/faculty/' + $localStorage.faculty_id + '/conferences/' + conference_id + '/assignments?format=ics&recording_time=true';
                var promise = $http.get($rootScope.global_app.uri + download_link).then(function (response) {

                }, function (error) {
                    //error
                    $log.info('error');
                })
                return promise;
            }
        }
    }
]);