'use strict'

angular.module('app').filter('unsafe', ['$sce',
    function ($sce) {
        return function (val) {
            if (val === parseInt(val, 10)) {
                return val;
            } else {
                return $sce.trustAsHtml(val);
            }
        };
    }
]);